import { render, staticRenderFns } from "./Zh.vue?vue&type=template&id=0e9bf2b2&scoped=true&"
import script from "./Zh.vue?vue&type=script&lang=js&"
export * from "./Zh.vue?vue&type=script&lang=js&"
import style0 from "./Zh.vue?vue&type=style&index=0&id=0e9bf2b2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e9bf2b2",
  null
  
)

export default component.exports